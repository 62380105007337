import * as React from "react"
import Layout from "../../../components/layout"
import { Router, Redirect } from "@reach/router"
import { Seo } from "../../../components/seo"
import { JsonDebugger } from "../../../components/json-debugger"
import { Button, Checkbox, Form, Input, Table, Tag, Typography } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import { graphql, Link } from "gatsby"
import { loginForm } from "./account.module.less"
import { StoreContext } from "../../../context/store-context"
import PrivateRoute from "../../../components/private-route"
import { OrderDetail } from "../../../components/order-detail"
import { OrderSummary } from "../../../components/order-summary"
import PageContent from "../../../components/page-content"

const { Title, Link: AntLink } = Typography
const titleCaseStatus = (status: string) =>
  status.replace(
    /([^\W_]+[^\s-]*) */g,
    // /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

const OrdersPage: React.FC = (data) => {
  const { loading, customer } = React.useContext(StoreContext)

  return (
    <Layout>
      {/* <Seo title="My Account" /> */}
      {/* <Title>My Account</Title> */}
      <PageContent>
        <Router basepath="/account/orders">
          <PrivateRoute
            path="/detail/:id"
            component={OrderDetail}
            title="Order Details"
          />
          <PrivateRoute
            path="/"
            component={OrderSummary}
            orders={customer.orders}
            title="Order Summary"
          />
        </Router>
      </PageContent>
      {/* <JsonDebugger json={{ data, customer }} /> */}
    </Layout>
  )
}

export default OrdersPage
