import { Table, Typography } from "antd"
import { Link } from "gatsby"
import React from "react"
import { JsonDebugger } from "./json-debugger"
import { Seo } from "./seo"
const { Title, Link: AntLink } = Typography

const titleCaseStatus = (status: string) =>
  status.replace(
    /([^\W_]+[^\s-]*) */g,
    // /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

const columns = [
  {
    title: "Orders",
    dataIndex: "name",
    key: "name",
    render: (text: string, record) => {
      return <Link to={`./detail/${record.id}`}>{text}</Link>
    },
  },
  {
    title: "Payment",
    dataIndex: "financialStatus",
    key: "payment",
    render: (text: string) => {
      return titleCaseStatus(text)
    },
  },
  {
    title: "Fulfillment",
    dataIndex: "fulfillmentStatus",
    key: "fulfillment",
    render: (text: string) => {
      return titleCaseStatus(text)
    },
  },
  {
    title: "Total",
    dataIndex: "amount",
    key: "total",
    render: (value: number = 0, record) => {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: record.currencyCode,
      })
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
]

export const OrderSummary: React.FC<{
  orders
  title?: string
}> = ({ orders, title }) => {
  const orderData = !!orders
    ? orders.map((order, index) => ({
        key: index.toString(),
        ...order,
      }))
    : []

  return (
    <>
      {title && (
        <>
          <Seo title={title} />
          <Title>{title}</Title>
        </>
      )}
      <Table dataSource={orderData} columns={columns} />
      {/* <JsonDebugger json={{ orders }} /> */}
    </>
  )
}
