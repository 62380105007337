import React from "react"
import { navigate } from "gatsby"
import { StoreContext } from "../context/store-context"
const PrivateRoute = ({ component: Component, location = null, ...rest }) => {
  const {
    customer: { authenticated },
  } = React.useContext(StoreContext)
  if (!authenticated && location?.pathname !== `/account/login`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/account/login`)
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
