import React, { useContext } from "react"
import { Button, Table, Tabs, Typography } from "antd"
import { JsonDebugger } from "./json-debugger"
import { Seo } from "./seo"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"
import { StoreContext } from "../context/store-context"

const { Title, Link: AntLink } = Typography

export const OrderDetail: React.FC<{
  title: string
  id: string
}> = ({ title, id }) => {
  const { addVariantToCart } = useContext(StoreContext)
  const columns = [
    {
      title: "Item",
      dataIndex: "title",
      key: "item",
      render: (value: string, record) => {
        return (
          <>
            {record?.variant && record.variant?.image && (
              <img
                alt={record.variant.image.altText}
                src={record.variant.image.transformedSrc}
                width="50px"
                height="50px"
              />
            )}
            {value}
          </>
        )
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (value: number = 0, record) => {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: record.currencyCode,
        })
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (value: number = 0, record) => {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: record.currencyCode,
        })
      },
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (value: number = 0, record) => {
        return (
          <Button
            disabled={!record?.variant?.availableForSale}
            onClick={(e) => {
              if (record.variant.id) {
                console.log("Add variant", { record })
                addVariantToCart(record.variant.id, 1)
              }
            }}
          >
            Add to cart
          </Button>
        )
      },
    },
  ]
  const { loading, data, error } = useQuery(ORDER_DETAIL_QUERY, {
    variables: {
      id,
    },
  })

  const items = data?.node?.lineItems?.edges
    ? data.node.lineItems.edges.map(({ node: item }) => ({
        quantity: item.quantity,
        currencyCode: data.node.currencyCode,
        title: item.title,
        price: parseFloat(item.discountedTotalPrice.amount),
        total: item.quantity * parseFloat(item.discountedTotalPrice.amount),
        variant: item.variant,
      }))
    : []

  return (
    <>
      {title && (
        <>
          <Seo title={title} />
          <Title>{title}</Title>
        </>
      )}
      <Table columns={columns} dataSource={items} />
      {/* <JsonDebugger json={{ id, title, loading, error, items, data }} /> */}
    </>
  )
}

const ORDER_DETAIL_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on Order {
        id
        currencyCode
        totalPriceV2 {
          amount
        }
        subtotalPriceV2 {
          amount
        }
        originalTotalPrice {
          amount
        }

        lineItems(first: 250) {
          edges {
            node {
              quantity
              title
              discountedTotalPrice {
                amount
              }
              originalTotalPrice {
                amount
              }
              discountAllocations {
                allocatedAmount {
                  amount
                }
              }
              variant {
                id
                availableForSale
                image {
                  altText
                  transformedSrc(maxWidth: 50, maxHeight: 50)
                }
              }
            }
          }
        }
      }
    }
  }
`
